//
// Default SCSS configuration (breakpoints, colors, etc) is loaded
// from @lamiaoy/scylla.
//
// If you want to overwrite for example a breakpoint or a color,
// create the corresponding file inside vars/ next to this env.scss file.
// Then import the base file from @lamiaoy/scylla,
// just like in the top of this file. Then check the overwriting instructions
// from that base file, as the instructions differ depending on variable type.
// Finally, include the new file here below.
//
// vars/colors has been created as an example of this.
//

// -----------------------------------------------------------------------------
// #FRONTEND LAMIA LIBRARY
// -----------------------------------------------------------------------------

@import '../../../../../../node_modules/@lamiaoy/scylla/css/includes/env';

// -----------------------------------------------------------------------------
// #GLOBAL-VARIABLES
// -----------------------------------------------------------------------------

@import 'vars/breakpoints';
@import 'vars/colors';

