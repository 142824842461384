@import '../../../../../../../node_modules/@lamiaoy/scylla/css/includes/vars/colors';

// -----------------------------------------------------------------------------
// #COLORS
// -----------------------------------------------------------------------------

//
// You can add and overwrite colors to the default colors
// by using map-get.
//
// @see "Overwriting existing color" in the base file.
//
//$color-black: map-set($color-black, cod-gray, #111);

